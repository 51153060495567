import BasePlugin from '../BasePlugin'

export default class AddAdjustmentRequestCommand extends BasePlugin {
  async execute () {
    let adjustmentRequestId = {
      'adjustmentRequestId': this.context.getModel().id
    }
    let me = this
    this.context.getCard().setLoading(true)
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AdjustmentRequest/AddAdjustmentRequestCommand`,
      adjustmentRequestId,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getCard().setLoading(false)
      this.context.getCard().$emit('cancelChanges')
      me.context.$msgbox({
        type: 'info',
        message: 'Расчет целевого сценария поставлен в очередь. Редактирование процедуры согласования не возможно. По завершении Вы получите уведомление'
      })
    })
  }
}
